import React from "react";
import { images } from "../../constant";

const BrandStory = () => {
  return (
    <div className="max-w-[1200px] px-5 py-5 lg:py-16  mx-auto">
      <div className="grid lg:grid-cols-2 lg:gap-4">
        <div className="max-w-[550px] py-3 ">
          <h2
            className="lg:text-xl text-sm font-normal font-outfit text-secondary uppercase"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            About
          </h2>
          <h1
            className="lg:text-[32px] text-[16px] font-semibold font-garamond text-black  tracking-wide leading-tight uppercase"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            Name : The Mula Art Gallery
          </h1>
          <p
            className="lg:text-2xl text-[16px] font-normal font-outfit text-left text-secondary lg:mb-5 my-2 lg:w-[500px] w-80"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            inspired by the word “MULA” represents the gallery's mission to
            provide a foundation for emerging artists to showcase their work and
            establish a foothold in the art world.
          </p>
          <p
            className="font-outfit lg:text-xl text-sm  ml-10 text-main lg:ml-40 "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            - Unearthing the Roots of Creativity
          </p>
        </div>
        <img
          src={images.brand}
          alt=""
          className="lg:w-[550px] w-80 mx-auto shadow-lg object-cover rounded-lg"
          data-aos="fade-up"
          data-aos-duration="700"
        />
      </div>
      <div className="grid lg:grid-cols-2 lg:gap-4 lg:mt-20 mt-5">
        <img
          src={images.brandvoice}
          alt=""
          className="lg:w-[500px] w-80 object-cover mx-auto rounded-lg lg:mx-0"
          data-aos="fade-up"
          data-aos-duration="700"
        />

        <div
          className="max-w-[550px] py-3 lg:py-12"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          <h2
            className="lg:text-[32px] text-[16px] font-semibold font-garamond text-black uppercase tracking-wide leading-tight "
            data-aos="fade-up"
            data-aos-duration="700"
          >
            Brand Voice
          </h2>
          <p
            className="lg:text-2xl text-[16px] font-normal font-outfit text-left text-secondary lg:mb-5 my-2 lg:w-[550px] w-80 "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            The gallery will have a friendly, approachable, and supportive
            voice, reflecting its commitment to creating an inclusive and
            community-focused space.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BrandStory;
