import React, { useEffect } from "react";
import "../../index.css";
import { useQuery } from "@apollo/client";
import { ALL_GALLERY } from "../../gql/gql";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer/Footer";
const Gallery = () => {
  const { data, loading } = useQuery(ALL_GALLERY);
  useEffect(() => {
    Aos.init();
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <div className="max-w-[1200px]  p-5 m-auto ">
          <div className="">
            <h1 className="text-center text-lg lg:text-[45px] font-garamond text-black tracking-wider lg:mb-10 my-2">
              Gallery
            </h1>
          </div>

          <div className="bg-background shadow-lg w-full flex p-3 mb-10">
            <Link to={"/"}>
              <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-normal">
                Home /
              </h1>
            </Link>
            <h1 className="lg:text-[20px] text-[16px] text-main font-outfit font-normal ml-1">
              Gallery
            </h1>
          </div>

          <div className="lg:columns-4 columns-2  space-y-2 mb-5 lg:mb-20 ">
            {data.traditional_art_work.map((image) => (
              <Link to={`/gallery/${image.id}`}>
                <div className="break-inside-avoid img-parent mb-4 bg-white shadow-xl ">
                  <div className="relative overflow-hidden">
                    <img
                      src={image.artwork_image_url}
                      alt=""
                      className="w-full h-auto m-auto img-child transition-all duration-1000 "
                    />
                  </div>
                  <h1 className="text-black lg:text-xl text-sm  font-outfit lg:font-medium  tracking-wider capitalize p-2">
                    {image.artwork_name.substring(0, 20)}
                  </h1>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default Gallery;
