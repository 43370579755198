import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";
import { images } from "../../constant";

const Quote = () => {
  const slideData = [
    {
      id: 1,
      title: "Vicent Van Gogh",
      description:
        "“If you hear a voice within you say you cannot paint, then by all means paint and that voice will be silenced.” ",
      img: images.vicentvangogh,
    },
    {
      id: 2,
      title: "Pablo Picasso",
      description:
        "“Painting is poetry which is seen and not heard, and poetry is a painting which is heard but not seen.” ",
      img: images.pablo,
    },
    {
      id: 3,
      title: "Leonardo da Vinci",
      description:
        "“Learning is the only thing the mind never exhausts, never fears, and never regrets.” ",
      img: images.leonardo,
    },
    {
      id: 4,
      title: "James Baldwin",
      description:
        "“The purpose of art is to lay bare the questions that have been concealed by the answers.” ",
      img: images.james,
    },
    {
      id: 5,
      title: "Robert Henri",
      description:
        "“Realize that a drawing is not a copy. It is a construction in very different materials. A drawing is an invention.” ",
      img: images.robert,
    },
  ];
  return (
    <div className="max-w-[1200px] mx-auto   overflow-x-hidden px-7 pt-10">
      <h2
        className="lg:text-lg  text-[12px] text-secondary uppercase"
        data-aos="fade-up"
        data-aos-duration="700"
      >
        Quote
      </h2>
      <h1
        className="lg:text-[32px] text-[16px]text-black font-garamond font-semibold uppercase mb-5 tracking-wider"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Artists' Famous Quote
      </h1>

      <Swiper
        // navigation={true}
        pagination={{ clickable: true }}
        spaceBetween={30}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          990: {
            slidesPerView: 3,
          },
        }}
        modules={[Pagination]}
        className=""
      >
        {slideData.map((slide) => (
          <SwiperSlide key={slide.id} className="w-full pb-24 relative ">
            <div class="flex flex-col items-center  bg-background shadow-lg px-5 pb-12 pt-3 rounded-lg">
              <p class="text-xl font-garamond text-center">
                {slide.description}
              </p>
              <div className="absolute bottom-[30px]  ">
                <img
                  src={slide.img}
                  className="object-cover w-20 h-20  rounded-full mx-auto"
                  alt=""
                />
                <h1 className="text-center">{slide.title}</h1>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Quote;
