// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// // import { useQuery } from "@apollo/client";
// // import { COLLECTION } from "../gql/gql";
// import { icons, images } from "../../constant";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import "../../index.css";
// const Collection = () => {
//   // data from gql
//   // const { data, loading, error } = useQuery(COLLECTION);
//   // if (loading) {
//   //   return <div>Loading....</div>;
//   // }
//   // if (error) {
//   //   return <div>Something is wrong...</div>;
//   // }
//   //   console.log(data);
//   useEffect(() => {
//     Aos.init();
//   }, []);
//   const imageData = [
//     {
//       img: images.maskgroup1,
//       id: 1,
//       name: "Starry Night",
//       artist: "Vicent Van Gogh",
//       medium: "Watercolor",
//       Dimension: "",
//       description:
//         "This artwork is about the work place of wood industry. For many years before, Burmese used elephants for carrying the woods from the forest to the work space",
//       year: 2000,
//     },
//     {
//       img: images.maskgroup2,
//       id: 2,
//       name: "Starry Night",
//       artist: "Vicent Van Gogh",
//       medium: "Watercolor",
//       Dimension: "",
//       description:
//         "This artwork is about the work place of wood industry. For many years before, Burmese used elephants for carrying the woods from the forest to the work space",
//       year: 2000,
//     },
//     {
//       img: images.maskgroup3,
//       id: 3,
//       name: "Starry Night",
//       artist: "Vicent Van Gogh",
//       medium: "Watercolor",
//       Dimension: "",
//       description:
//         "This artwork is about the work place of wood industry. For many years before, Burmese used elephants for carrying the woods from the forest to the work space",
//       year: 2000,
//     },
//     {
//       img: images.maskgroup4,
//       id: 4,
//       name: "Starry Night",
//       artist: "Vicent Van Gogh",
//       medium: "Watercolor",
//       Dimension: "",
//       description:
//         "This artwork is about the work place of wood industry. For many years before, Burmese used elephants for carrying the woods from the forest to the work space",
//       year: 2000,
//     },

//     {
//       img: images.maskgroup5,
//       id: 5,
//       name: "Starry Night",
//       artist: "Vicent Van Gogh",
//       height: 200,
//       medium: "Watercolor",
//       Dimension: "",
//       description:
//         "This artwork is about the work place of wood industry. For many years before, Burmese used elephants for carrying the woods from the forest to the work space",
//       year: 2000,
//     },
//   ];
//   //modal box data
//   const [detail, setDetail] = useState();
//   const viewImage = (image) => {
//     setDetail(image);
//   };
//   //model box close btn
//   const closeBtn = (action) => {
//     if (!action) {
//       setDetail(null);
//     }
//   };
//   //background scroll disable when model box open
//   useEffect(() => {
//     const body = document.querySelector("body");
//     body.style.overflow = detail ? "hidden" : "auto";
//   }, [detail]);
//   return (
//     <div
//       id="collections"
//       className=" mx-auto relative pb-5 lg:pb-10 px-7 max-w-[1200px]"
//     >
//       <h2
//         className="lg:text-lg text-[12px] text-secondary font-outfit uppercase"
//         data-aos="fade-up"
//         data-aos-duration="700"
//         //
//       >
//         collections
//       </h2>
//       <div className="flex justify-between ">
//         <h1
//           className="text-[16px] lg:text-[32px] font-garamond font-semibold text-black lg:tracking-wider  lg:my-3 mb-5 lg:mb-12 uppercase "
//           data-aos="fade-up"
//           data-aos-duration="700"
//           //
//         >
//           Our Lastest Best Collections
//         </h1>
//         <Link to={"/gallery"}>
//           <h3
//             className="capitalize text-[12px] lg:text-lg text-main underline lg:my-4 my-1 font-outfit"
//             data-aos="fade-up"
//             data-aos-duration="900"
//           >
//             see all
//           </h3>
//         </Link>
//       </div>
//       {/* modal box data condition */}
//       {detail && detail.img && (
//         <div className="w-full h-screen top-0 left-0  fixed z-20  overflow-hidden bg-white ">
//           {/* <div className="lg:max-w-[1200px] mx-auto lg:flex justify-center items-center ">
//             <div className="lg:max-w-[55%] lg:max-h[90%] mx-auto p-5">
//               <img
//                 src={detail.img}
//                 alt=""
//                 className="lg:w-[550px] object-cover h-[400px] max-w-[90%] max-h-[90%] "
//               />
//             </div>
//             <div className="lg:w-[45%] w-full h-screen lg:flex flex-col justify-center bg-white p-5 lg:p-7">
//               <h1 className="lg:text-[32px] text-[28px] tracking-wider font-high font-semibold text-black ">
//                 {detail.name}
//               </h1>
//               <h2 className="lg:text-2xl lg:w-full text-sm text-black font-outfit font-normal my-2 tracking-wider">
//                 Artist- {detail.artist}
//               </h2>
//               <h2 className="lg:text-2xl lg:w-full text-sm text-black font-outfit font-normal tracking-wider">
//                 Medium-
//                 {detail.medium}
//               </h2>
//               <h2 className="lg:text-2xl lg:w-full text-sm text-black font-outfit font-normal my-2 tracking-wider">
//                 Dimesions-
//               </h2>
//               <h2 className="lg:text-2xl lg:w-full text-sm text-black font-outfit font-normal mb-5 tracking-wider">
//                 Year- {detail.year}
//               </h2>
//               <span className="lg:text-2xl lg:w-full text-[16px] font-outfit font-normal text-secondary tracking-wider ">
//                 Description
//               </span>
//               <p className="lg:text-2xl lg:w-full text-[16px] text-justify text-black font-outfit font-normal tracking-wider">
//                 {detail.description}
//               </p>
//             </div>
//           </div> */}
//           <div className="max-w-[1200px] mx-auto h-screen p-7 relative">
//             <img
//               src={icons.cross}
//               alt=""
//               onClick={() => closeBtn()}
//               className="absolute lg:top-16 lg:left-8 w-5 h-5 top-4 left-3 lg:w-7 lg:h-7 object-cover"
//             />
//             <div className="grid lg:grid-cols-2 lg:my-32 my-5 ">
//               <div className="mx-auto">
//                 <img
//                   src={detail.img}
//                   alt=""
//                   className="lg:w-[500px] lg:h-[400px] w-full h-auto object-cover"
//                 />
//               </div>
//               <div className="">
//                 <h1 className="lg:text-[32px] text-[28px] tracking-wider font-high font-semibold text-black ">
//                   {detail.name}
//                 </h1>
//                 <h2 className="lg:text-2xl lg:w-full text-sm text-black font-outfit font-normal my-2 tracking-wider">
//                   Artist- {detail.artist}
//                 </h2>
//                 <h2 className="lg:text-2xl lg:w-full text-sm text-black font-outfit font-normal tracking-wider">
//                   Medium-
//                   {detail.medium}
//                 </h2>
//                 <h2 className="lg:text-2xl lg:w-full text-sm text-black font-outfit font-normal my-2 tracking-wider">
//                   Dimesions-
//                 </h2>
//                 <h2 className="lg:text-2xl lg:w-full text-sm text-black font-outfit font-normal mb-5 tracking-wider">
//                   Year- {detail.year}
//                 </h2>
//                 <span className="lg:text-2xl lg:w-full text-[16px] font-outfit font-normal text-secondary tracking-wider ">
//                   Description
//                 </span>
//                 <p className="lg:text-2xl lg:w-full text-[16px] text-justify text-black font-outfit font-normal tracking-wider">
//                   {detail.description}
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       {/* end */}
//       <div className="grid lg:grid-cols-3 grid-cols-2  gap-4 ">
//         {imageData.map((image) => (
//           <div
//             className={
//               image.height < 210
//                 ? "col-span-2 lg:w-[760px] lg:h-[426px] w-full cursor-pointer img-parent overflow-hidden relative"
//                 : "cursor-pointer img-parent overflow-hidden relative"
//             }
//             data-aos="fade-up"
//             data-aos-duration="700"
//             onClick={() => viewImage(image)}
//           >
//             <img
//               src={image.img}
//               alt=""
//               className="object-cover img-child transition-all duration-1000"
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Collection;

import { useQuery } from "@apollo/client";
import { COLLECTION } from "../../gql/gql";
import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Loading";

const Collection = () => {
  const { data, loading } = useQuery(COLLECTION);
  console.log(data);
  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="max-w-[1200px] mx-auto p-7" id="collection">
        <h2
          className="lg:text-lg text-[12px] text-secondary font-outfit uppercase"
          data-aos="fade-up"
          data-aos-duration="700"
          //
        >
          collections
        </h2>
        <div className="flex justify-between ">
          <h1
            className="text-[16px] lg:text-[32px] font-garamond font-semibold text-black lg:tracking-wider  lg:my-3 mb-5 lg:mb-12 uppercase "
            data-aos="fade-up"
            data-aos-duration="700"
            //
          >
            Our Lastest Best Collections
          </h1>
          <Link to={"/gallery"}>
            <h3
              className="capitalize text-[12px] lg:text-lg text-main underline lg:my-4 my-1 font-outfit"
              data-aos="fade-up"
              data-aos-duration="900"
            >
              see all
            </h3>
          </Link>
        </div>
        <div className="mb-5 grid lg:grid-cols-3 grid-cols-2 lg:gap-4 gap-4">
          {data?.traditional_art_work?.map((gallery, index) => (
            <div
              key={index}
              className={
                gallery.width >= 32 && gallery.height <= 24
                  ? "col-span-2 img-parent"
                  : " img-parent  "
              }
            >
              <Link to={`/gallery/${gallery.id}`}>
                <div className="relative overflow-hidden ">
                  <img
                    src={gallery.artwork_image_url}
                    alt=""
                    className={
                      gallery.width >= 24 && gallery.height <= 24
                        ? "w-full lg:h-[500px] h-[200px] bg-auto   img-child transition-all duration-1000 "
                        : "w-full lg:h-[500px] h-[200px] object-cover  img-child transition-all duration-1000 "
                    }
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default Collection;
