// import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { icons } from "../../constant";
import { useQuery } from "@apollo/client";
import { ALL_EVENT } from "../../gql/gql";

const Event = () => {
  const { data, loading, error } = useQuery(ALL_EVENT);
  console.log({ data, loading, error });

  let today = new Date();
  console.log(today);

  const filterPastDate = data?.event?.filter((item) => {
    const itemDate = new Date(item.event_date_time);
    console.log(itemDate);
    return itemDate <= today;
  });
  const filterUpcomingDate = data?.event?.filter((item) => {
    const itemDate = new Date(item.event_date_time);
    console.log(itemDate);
    return itemDate >= today;
  });
  console.log(filterPastDate);
  if (loading) {
    return <h1>Loading..</h1>;
  } else {
    return (
      <>
        <div className="max-w-[1200px] mx-auto pb-10 px-5 lg:px-0" id="event">
          <img
            src={icons.logo}
            alt=""
            className="lg:w-72 lg:h-28 w-40 h-14 mx-auto object-cover"
          />

          <div className="bg-background shadow-lg w-full flex p-3">
            <Link to={"/"}>
              <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-medium">
                Home /
              </h1>
            </Link>
            <Link to={"/gallery"}>
              <h1 className="lg:text-[20px] text-[16px] text-main font-outfit font-bold ml-1">
                Event
              </h1>
            </Link>
          </div>

          <div className="lg:my-10  my-5">
            <h1
              className={
                filterUpcomingDate?.length
                  ? "text-sm text-black font-garamond font-semibold uppercase mb-3 tracking-wider   lg:text-[32px] lg:mb-10 inline-block border-b-2 border-main pb-2"
                  : "hidden"
              }
            >
              Upcoming Event
            </h1>

            <div className=" grid lg:grid-cols-2 gap-5">
              {filterUpcomingDate?.map((item) => (
                <Link to={`/event/${item.id}`}>
                  <div key={item.id} className="relative">
                    <img
                      src={item.event_thumbnail_url}
                      alt=""
                      className=" lg:h-[400px] w-full  h-[236px]  object-cover"
                    />
                    <h1
                      className="absolute bg-black w-full opacity-30 text-white text-sm lg:text-2xl font-outfit font-normal tracking-wider  bottom-0 lg:p-4 p-2
      "
                    >
                      {item.event_name}
                    </h1>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className=" ">
            <h1
              className={
                filterPastDate?.length
                  ? "text-[16px]  text-black font-garamond font-semibold uppercase mb-3  lg:text-[32px] lg:mb-10 border-b-2 inline-block border-main"
                  : "hidden"
              }
            >
              Past Event
            </h1>
            <div className=" grid lg:grid-cols-2 mx-auto gap-5">
              {filterPastDate?.map((item) => (
                <div key={item.id} className="relative">
                  <Link to={`/event/${item.id}`}>
                    <img
                      src={item.event_thumbnail_url}
                      alt=""
                      className="  lg:h-[400px] w-full h-[236px]  object-cover"
                    />
                  </Link>
                  <h1
                    className="absolute bg-black w-full  opacity-30 text-white lg:text-[20px] font-outfit font-normal tracking-wider bottom-0 lg:p-4 p-2 text-sm
      "
                  >
                    {item.event_name}
                  </h1>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default Event;
