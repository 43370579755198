import { Routes, Route } from "react-router-dom";
import Main from "../src/components/Main";
import Event from "../src/pages/Event/Event";
import Artist from "../src/pages/Artist/Artist";
import Gallery from "../src/pages/Gallery/Gallery";
import BrandStory from "../src/pages/BrandStory/BrandStory";
import GalleryDetail from "../src/pages/Gallery/GalleryDetail";
import ArtistDetail from "../src/pages/Artist/ArtistDetail";
import Privacy from "./components/Privacy";
import TermsConditions from "./components/TermsConditions";
import EventDetail from "./pages/Event/EventDetal";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:id" element={<GalleryDetail />} />
        <Route path="/artist/:id" element={<ArtistDetail />} />
        <Route path="/brandstory" element={<BrandStory />} />
        <Route path="/artist" element={<Artist />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/terms&conditions" element={<TermsConditions />} />
        <Route path="/event" element={<Event />} />
        <Route path="/event/:id" element={<EventDetail />} />
      </Routes>
    </div>
  );
}

export default App;
