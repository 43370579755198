import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { icons } from "../../constant";
import { Link, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { ARTIST_DETAIL } from "../../gql/gql";
import { ALL_GALLERY_DETAILS } from "../../gql/gql";
import Loading from "../../components/Loading";
const ArtistDetail = () => {
  //artist details
  const { id } = useParams();
  const [loadArtistData, resultArtistData] = useLazyQuery(ARTIST_DETAIL);
  console.log(loadArtistData);
  const [artist, setArtist] = useState(null);

  //artist's artworks from gallery
  const [loadArtistArtwork, resultArtistArtwork] =
    useLazyQuery(ALL_GALLERY_DETAILS);
  const [artistArtwork, setartistArtwork] = useState(null);

  //artist details
  useEffect(() => {
    if (id) {
      loadArtistData({ variables: { ArtistId: id } });
    }
  }, [id, loadArtistData]);
  useEffect(() => {
    console.log(resultArtistData);
    if (resultArtistData.data) {
      setArtist(resultArtistData.data.artist_by_pk);
    }
  }, [resultArtistData]);
  console.log(artist);

  //artist's artwork from gallery
  useEffect(() => {
    if (artist) {
      setLoading(true);
      loadArtistArtwork({
        variables: { ArtistId: artist.id },
      });
      setLoading(false);
    }
  }, [artist, loadArtistArtwork]);
  useEffect(() => {
    // console.log(resultArtistArtwork);
    if (resultArtistArtwork.data) {
      setartistArtwork(resultArtistArtwork.data.traditional_art_work);
    }
  }, [resultArtistArtwork]);

  const [loading, setLoading] = useState(false);
  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <div className=" mx-auto max-w-[1200px] px-5 ">
          <div className="">
            <Link to={"/"}>
              <img
                src={icons.logo}
                alt=""
                className="lg:w-72 lg:h-28 w-40 h-14 mx-auto"
              />
            </Link>
            <div className="bg-background shadow-lg w-full flex p-3">
              <Link to={"/"}>
                <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-normal">
                  Home /
                </h1>
              </Link>
              <Link to={"/artist"}>
                <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-normal">
                  Artist /
                </h1>
              </Link>
              <h1 className="lg:text-[20px] text-[16px] text-main font-outfit font-normal">
                ArtistDetails
              </h1>
            </div>
            {artist && (
              <div className="grid lg:grid-cols-2 my-5  ">
                <div className=" ">
                  <img
                    src={artist.artist_profile_image_url}
                    alt=""
                    className="w-full h-auto object-cover"
                  />
                </div>
                <div className="lg:p-5 py-5 ">
                  <h3 className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal tracking-wider">
                    Artist Name -{" "}
                    <span className="text-black font-medium">
                      {artist.artist_name}
                    </span>
                  </h3>
                  <h3 className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal tracking-wider my-2">
                    Born -{" "}
                    <span className="text-black">{artist.year_born}</span>
                  </h3>

                  <h3 className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal tracking-wider">
                    Biography
                  </h3>
                  <p
                    className="lg:text-2xl text-[16px] text-black font-outfit font-medium tracking-wider text-justify"
                    dangerouslySetInnerHTML={{ __html: artist.biography }}
                  ></p>
                </div>
              </div>
            )}
          </div>
          <div className="max-w-[1200px] mx-auto">
            <h1 className="lg:text-3xl text-lg text-black font-garamond font-bold tracking-wider lg:mb-10 mb-5 border-b-2 border-main inline-block pb-2">
              See Artworks
            </h1>

            <div className="lg:columns-4 columns-2  space-y-3 mb-10 lg:mb-20">
              {artistArtwork?.map((a) => (
                <div className=" break-inside-avoid">
                  <Link to={`/gallery/${a.id}`}>
                    <img src={a.artwork_image_url} alt="" />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default ArtistDetail;
