import React from "react";
import { Link, useParams } from "react-router-dom";
import { icons } from "../../constant";
import { useLazyQuery } from "@apollo/client";
import { ALL_ARTWORK_DETAIL_ID } from "../../gql/gql";

import { GET_GALLERY_BY_OWNERSHIP } from "../../gql/gql";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading";

const GalleryDetail = () => {
  //Artwork Detail Data with id
  const { id } = useParams();
  const [loadData, resultData, loading] = useLazyQuery(ALL_ARTWORK_DETAIL_ID);

  console.log(resultData);
  React.useEffect(() => {
    loadData({
      variables: {
        id,
      },
    });
  }, [loadData, id]);

  const [loadGalleryId, resultGalleryData] = useLazyQuery(
    GET_GALLERY_BY_OWNERSHIP
  );
  console.log(resultGalleryData);
  React.useEffect(() => {
    loadGalleryId({
      variables: {
        fk_ownership_id:
          resultData?.data?.traditional_art_work_by_pk.fk_ownership_id,
      },
    });
  }, [loadGalleryId, resultData?.data?.traditional_art_work_by_pk]);
  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <div className=" mx-auto max-w-[1200px] px-7 ">
          <div className="">
            <Link to={"/"}>
              <img
                src={icons.logo}
                alt=""
                className="lg:w-72 lg:h-28 w-40 h-14 mx-auto"
              />
            </Link>
            <div className="bg-background shadow-lg w-full flex p-3">
              <Link to={"/"}>
                <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-normal">
                  Home /
                </h1>
              </Link>
              <Link to={"/gallery"}>
                <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-normal">
                  Gallery /
                </h1>
              </Link>
              <h1 className="lg:text-[20px] text-[16px] text-main font-outfit font-normal">
                GalleryDetails
              </h1>
            </div>
            {resultData?.data?.traditional_art_work_by_pk && (
              <div className="grid lg:grid-cols-2 my-10 ">
                <div className="border border-white shadow-xl">
                  <img
                    src={
                      resultData?.data?.traditional_art_work_by_pk
                        .artwork_image_url
                    }
                    alt=""
                    className={
                      resultData?.data?.traditional_art_work_by_pk.width <=
                        24 &&
                      resultData?.data?.traditional_art_work_by_pk.height <= 36
                        ? "w-auto lg:h-[500px] h-auto mx-auto "
                        : "w-full lg:h-[500px] h-auto  object-cover"
                    }
                  />
                </div>
                <div className="lg:px-10 mt-3">
                  <h1 className="lg:text-3xl text-lg font-outfit font-semibold text-black tracking-wider mb-2">
                    {resultData?.data?.traditional_art_work_by_pk.artwork_name}
                  </h1>
                  <h3 className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal tracking-wider">
                    Artist Name -
                    <span className="text-black">
                      {
                        resultData?.data?.traditional_art_work_by_pk
                          .traditional_art_work_artist.artist_name
                      }
                    </span>
                  </h3>
                  <h3 className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal tracking-wider my-2">
                    Medium -
                    <span className="text-black">
                      {
                        resultData?.data?.traditional_art_work_by_pk
                          .traditional_art_work_artwork_medium_type.medium_name
                      }
                    </span>
                  </h3>
                  <h3 className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal tracking-wider">
                    Dimensions -
                    <span className="lg:text-2xl text-[16px] text-black font-outfit font-normal">
                      {resultData?.data?.traditional_art_work_by_pk.height} " x
                      {resultData?.data?.traditional_art_work_by_pk.width} "
                    </span>
                  </h3>
                  <h2 className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal tracking-wider my-2">
                    Year -
                    <span className="text-black">
                      {
                        resultData?.data?.traditional_art_work_by_pk
                          .artwork_year
                      }
                    </span>
                  </h2>
                  <h3 className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal tracking-wider">
                    Description
                  </h3>
                  <p
                    className="lg:text-2xl text-[16px] text-black font-outfit font-normal tracking-wider"
                    dangerouslySetInnerHTML={{
                      __html:
                        resultData?.data?.traditional_art_work_by_pk
                          .description,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className=" max-w-[1200px] mx-auto  mb-10 px-7">
          <h1 className="lg:text-2xl text-lg font-garamond font-semibold text-black mb-10 border-b-2 border-main inline-block pb-2">
            Artworks You Might Love
          </h1>
          <div className="lg:columns-4 columns-2 space-y-2 lg:mb-20">
            {resultGalleryData?.data?.traditional_art_work.map(
              (gallery, index) => (
                <Link key={index} to={`/gallery/${gallery.id}`}>
                  <div className="break-inside-avoid mb-3">
                    <img
                      src={gallery?.artwork_image_url}
                      alt=""
                      className=" w-full h-auto "
                    />
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default GalleryDetail;
