import React from "react";
import { images, icons } from "../../constant";
const Footer = () => {
  return (
    <div className="w-full grid lg:grid-cols-12 " id="footer">
      <div className="lg:col-span-6">
        <img
          src={images.footer}
          alt=""
          className="w-full lg:h-[320px] h-auto object-cover"
        />
      </div>
      <div className="bg-background lg:col-span-6">
        <div className="grid lg:grid-cols-2">
          <div className="lg:p-12  p-5">
            <h1 className="lg:text-[20px] text-lg text-black font-garamond font-semibold tracking-wider uppercase">
              Contact Us
            </h1>
            <ul>
              <li className="lg:text-[20px] text-[16px] text-secondary font-outfit font-normal my-3 w-80 ">
                The Secretariat Yangon,Thein Phyu Road, Botahtaung Township,
                Yangon
              </li>
              <li className="text-outfit lg:text-[20px] text-[16px] text-secondary">
                +959 962277099
              </li>
            </ul>
          </div>
          <div className="lg:p-12  px-5 pb-5">
            <h1 className="uppercase lg:text-[20px] text-lg text-black font-garamond font-semibold tracking-wider">
              Opening Hours
            </h1>
            <ul className="my-3">
              <li className="lg:text-[20px] text-[16px] text-secondary font-normal font-outfit">
                Monday to Sunday
              </li>
              <li className="lg:text-[20px] text-[16px] text-secondary font-outfit font-normal">
                10:00am - 5:00pm
              </li>
            </ul>
            <div className="">
              <h1 className="lg:text-[20px] text-lg text-black font-garamond font-semibold tracking-wider mb-3 uppercase">
                Follow Us On
              </h1>
              <a href="https://www.facebook.com/mula.com.mm">
                <img
                  src={icons.facebook}
                  alt=""
                  className="w-[24px] h-[24px] rounded-full object-cover hover:cursor-pointer"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="lg:w-[350px] w-[250px] h-[2px] bg-secondary mx-auto"></div>
        <p className="text-[14px] lg:text-[16px] text-center text-secondary font-outfit font-normal my-2">
          &copy; Copyright . All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
