import maskgroup2 from "../assets/images/Rectangle 94.png";
import maskgroup1 from "../assets/images/Mask group.png";
import maskgroup3 from "../assets/images/Mask group (1).png";
import maskgroup5 from "../assets/images/Mask group (2).png";
import maskgroup4 from "../assets/images/Mask group (3).png";
import herobg from "../assets/images/bg.jpg";
import eventbg from "../assets/images/eventbg.jpg";
import eventImg1 from "../assets/images/eventitem.jpg";
import eventImg2 from "../assets/images/eventitem2.jpg";
import eventImg3 from "../assets/images/art shhow.png";
import ads from "../assets/images/mobileapp.png";
import brand from "../assets/images/brand.jpg";
import brandvoice from "../assets/images/brandvoice.jpg";
import brandstory from "../assets/images/brandstory.jpg";
import bstorybg from "../assets/images/bstorybg.jpg";
import vicentvangogh from "../assets/images/vangogh.jpg";
import pablo from "../assets/images/pablo picasso.jpeg";
import james from "../assets/images/jamesbaldwin.jpeg";
import leonardo from "../assets/images/leonado da vinci.jpeg";
import robert from "../assets/images/robert.jpeg";

import watpad from "../assets/images/watpad.jpg";
import culture from "../assets/images/culture.jpg";
import indaba from "../assets/images/indaba.jpg";
import dezeen from "../assets/images/dezeen.jpg";
import footer from "../assets/images/sy.jpg";
import popularArtwork1 from "../assets/images/popularArtwork1.jpg";
import popularArtwork2 from "../assets/images/popularArtwork2.jpg";
import popularArtwork3 from "../assets/images/popularArtwork3.jpg";

export default {
  popularArtwork1,
  popularArtwork2,
  popularArtwork3,
  maskgroup1,
  maskgroup2,
  maskgroup3,
  maskgroup4,
  maskgroup5,
  herobg,
  eventbg,
  eventImg1,
  eventImg2,
  eventImg3,
  ads,
  brand,
  brandvoice,
  brandstory,
  bstorybg,
  vicentvangogh,
  pablo,
  james,
  leonardo,
  robert,
  watpad,
  culture,
  indaba,
  dezeen,
  footer,
};
