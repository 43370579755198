import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { EVENT_DETAIL } from "../../gql/gql";
import { useLazyQuery } from "@apollo/client";
import Footer from "../../components/Footer/Footer";
import { icons } from "../../constant";

const EventDetail = () => {
  const { id } = useParams();
  const [loadEventData, resultEventData] = useLazyQuery(EVENT_DETAIL);
  const [artist, setArtist] = useState(null);

  useEffect(() => {
    if (id) {
      loadEventData({ variables: { EventId: id } });
    }
  }, [id, loadEventData]);
  useEffect(() => {
    console.log(resultEventData);
    if (resultEventData.data) {
      setArtist(resultEventData.data.event_by_pk);
    }
  }, [resultEventData]);
  console.log(artist);

  // console.log(loadData);
  return (
    <>
      <div className="max-w-[1200px] h-screen mx-auto p-7 ">
        <div className="bg-background shadow-lg w-full flex p-3 mb-5">
          <Link to={"/"}>
            <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-medium">
              Home /
            </h1>
          </Link>
          <Link to={"/event"}>
            <h1 className="lg:text-[20px] text-[16px] font-outfit text-secondary ml-1">
              Event /
            </h1>
          </Link>
          <Link to={"/event"}>
            <h1 className="lg:text-[20px] text-[16px] text-main font-outfit font-bold ml-1">
              Event Detail
            </h1>
          </Link>
        </div>
        <div className="grid lg:grid-cols-2 lg:gap-4 mb-10">
          <img
            src={artist?.event_thumbnail_url}
            alt=""
            className="w-auto h-[600px] object-contain mx-auto shadow-lg"
          />
          <div className="lg:p-7 p-2 ">
            <h1 className="lg:text-[28px] text-xl font-garamond uppercase font-semibold text-main ">
              {artist?.event_name}
            </h1>
            <p
              className="lg:text-2xl text-secondary text-sm font-outfit font-normal my-3"
              dangerouslySetInnerHTML={{ __html: artist?.event_description }}
            ></p>
            <div className="flex items-center ">
              <img src={icons.calender} className="w-10 h-10 mr-3" alt="" />
              <p className="lg:text-md  font-outfit ">
                {new Date(artist?.event_date_time).toLocaleString("en-US")}
              </p>
              {/* <p className="ml-5 lg:text-md font-outfit">
                {artist?.event_location}
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventDetail;
