import menu from "../assets/icon/menuicon.png";
import cross from "../assets/icon/cross.png";
import logo from "../assets/logo/txt logo black.png";
import playstore from "../assets/icon/playstore.png";
import ios from "../assets/icon/appstore.png";
import facebook from "../assets/icon/fa-brands_facebook-square.png";
import scrollbtn from "../assets/icon/scrollbtn.png";
import calender from "../assets/icon/material-symbols_calendar-month-rounded.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  menu,
  cross,
  logo,
  ios,
  playstore,
  facebook,
  scrollbtn,
  calender,
};
