import React from "react";
import { images, icons } from "../../constant";
import { Link } from "react-router-dom";
const BrandStory = () => {
  return (
    <>
      <div className="w-full lg:h-[700px] h-[550px] relative ">
        <img
          src={images.bstorybg}
          alt=""
          className="absolute w-full lg:h-[700px] h-[550px]   opacity-20 object-cover"
        />
        <div className="max-w-[1200px] mx-auto p-7">
          <div className="">
            <img
              src={icons.logo}
              alt=""
              className="lg:w-72 lg:h-28 w-40 h-14 mx-auto object-cover"
            />
            {/* <div className="bg-background shadow-lg w-full flex p-3">
              <Link to={"/"}>
                <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-medium">
                  Home /
                </h1>
              </Link>
              <Link to={""}>
                <h1 className="lg:text-[20px] text-[16px] text-main font-outfit font-bold ml-1">
                  Brand Story
                </h1>
              </Link>
            </div> */}
            <div className="">
              <h1 className="lg:text-5xl text-black font-garamond font-medium tracking-wider border-b-2 border-main inline-block pb-2">
                Brand Story
              </h1>
              <div className="grid lg:grid-cols-2  lg:mt-10 mt-5  ">
                <p className="lg:text-2xl text-justify   font-outfit font-medium text-sm text-secondary lg:w-[500px] w-80 ">
                  The story of the "MULA" begins with a desire to connect people
                  with timeless, meaningful art. Our name "MULA" represents the
                  concept of an immeasurable period of time, which reflects the
                  gallery's commitment to showcasing art that has the power to
                  transcend time and touch people on a deep level.
                </p>
                <div className="lg:w-[550px] w-80 lg:px-5">
                  <p className="lg:text-2xl text-justify   font-outfit font-medium text-sm text-secondary ">
                    We believe that art has the ability to evoke emotion, spark
                    imagination, and inspire change. Our goal is to provide a
                    platform for emerging and established artists to showcase
                    their work and connect with a wider audience.
                  </p>
                  <p className="lg:text-2xl  text-justify  mt-3 font-outfit font-medium text-sm text-secondary ">
                    At MULA, we believe that art should be accessible to
                    everyone, regardless of background or experience. We host
                    regular exhibitions and events that are open to the public,
                    as well as art classes and workshops for the community.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1200px] mx-auto px-7 lg:py-16 py-10">
        <div className="grid lg:grid-cols-2">
          <img
            src={images.brandstory}
            alt=""
            className="lg:w-[470px] lg:h-[650px] w-[350px] h-[450px] rounded-lg object-cover"
          />
          <div className="lg:w-[570px] py-4 w-80 mx-auto lg:mx-0">
            <p className="lg:text-2xl text-sm text-secondary font-outfit font-normal text-justify">
              Our brand aesthetic is clean and minimalistic, with a focus on
              showcasing the art rather than overwhelming it with unnecessary
              distractions. We use a neutral color palette and a modern,
              sans-serif font to reflect our contemporary focus.
            </p>
            <p className="lg:text-2xl text-sm text-secondary font-outfit font-normal text-justify my-3">
              We are committed to fostering a sense of community at MULA Art
              Gallery. We believe that art should be a shared experience and we
              strive to create a space where people can come together to
              appreciate and discuss art in a welcoming and inclusive
              environment.
            </p>
            <p className="lg:text-2xl text-sm text-secondary font-outfit font-normal text-justify ">
              We are committed to fostering a sense of community at MULA Art
              Gallery. We believe that art should be a shared experience and we
              strive to create a space where people can come together to
              appreciate and discuss art in a welcoming and inclusive
              environment.
            </p>
            <p className="lg:text-[20px] text-sm text-main lg:w-80 w-72  text-right lg:ml-60 ml-7 mt-3 font-outfit font-semibold">
              MULA: The Art Gallery, Timeless art for an ever-changing world.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandStory;
