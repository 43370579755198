import { useEffect } from "react";
import "../index.css";
import Aos from "aos";
import "aos/dist/aos.css";
import "animate.css/animate.min.css";
import BrandStory from "./About/BrandStory";
import Event from "./Event/Event";
import Footer from "./Footer/Footer";
import Ads from "./Ads/Ads";
import Collection from "./Collection/Collection";
import Quote from "./Quote/Quote";
import Hero from "./Header/Hero";
const Main = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="scroll-smooth">
      {/* Hero */}
      <Hero />
      {/* 
      Brand Story */}
      <BrandStory />
      {/* Collections */}
      <Collection />
      {/* Event */}
      <Event />

      {/* Visitor */}
      <Quote />
      {/* Ads*/}
      <Ads />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Main;
