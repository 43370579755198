import { gql } from "@apollo/client";

//Gallery Data
export const ALL_GALLERY = gql`
  query GALLERY {
    traditional_art_work(
      where: { _and: { disabled: { _eq: false } }, pending: { _eq: false } }
    ) {
      artwork_image_url
      artwork_name
      artwork_year
      description
      id
      traditional_art_work_artist {
        artist_name
      }
      traditional_artwork_dimension {
        dimension_name
      }
      traditional_art_work_artwork_medium_type {
        medium_name
      }
      width
      height
      disabled
      pending
    }
  }
`;
//Gallery Detail Data with id
export const ALL_GALLERY_DETAILS = gql`
  query GALLETY_DETAIL($ArtistId: Int!) {
    traditional_art_work(
      where: {
        traditional_art_work_artist: { id: { _eq: $ArtistId } }
        _and: { disabled: { _eq: false } }
        pending: { _eq: false }
      }
    ) {
      artwork_image_url
      id
      traditional_art_work_artist {
        id
        artist_name
      }
    }
  }
`;
export const ALL_ARTISTS = gql`
  query MyQuery {
    artist(
      where: {
        _and: {
          disabled: { _eq: false }
          traditional_or_digital_preferred: { _eq: "Traditional" }
        }
      }
    ) {
      artist_name
      artist_profile_image_url
      id
    }
  }
`;
export const ARTIST_DETAIL = gql`
  query ARTIST_DETAIL($ArtistId: Int!) {
    artist_by_pk(id: $ArtistId) {
      artist_name
      artist_profile_image_url
      biography
      year_born
      id
    }
  }
`;

export const GET_GALLERY_BY_OWNERSHIP = gql`
  query MyQuery($fk_ownership_id: Int!) {
    traditional_art_work(
      where: {
        fk_ownership_id: { _eq: $fk_ownership_id }
        _and: { disabled: { _eq: false } }
        pending: { _eq: false }
      }
    ) {
      id
      artwork_image_url
    }
  }
`;
export const ALL_ARTWORK_DETAIL_ID = gql`
  query allArtworkDetailById($id: Int!) {
    traditional_art_work_by_pk(id: $id) {
      artwork_image_url
      artwork_name
      artwork_year
      description
      dimensions
      width
      height
      id
      fk_ownership_id
      traditional_art_work_artist {
        artist_name
        id
      }
      traditional_art_work_artwork_medium_type {
        medium_name
      }
      traditional_artwork_dimension {
        dimension_name
      }
    }
  }
`;
export const ALL_EVENT = gql`
  query EVENT {
    event {
      event_date_time
      event_description
      event_location
      event_name
      id
      event_thumbnail_url
    }
  }
`;
export const EVENT_DETAIL = gql`
  query MyQuery($EventId: Int!) {
    event_by_pk(id: $EventId) {
      event_date_time
      event_end_time
      event_location
      event_name
      event_thumbnail_url
      event_description
    }
  }
`;
export const COLLECTION = gql`
  query COLLECTION {
    traditional_art_work(
      limit: 5
      where: { _and: { disabled: { _eq: false }, pending: { _eq: false } } }
    ) {
      artwork_image_url
      id
      height
      fk_artist_id
      width
    }
  }
`;
