import React from "react";
import loader from "../assets/images/mulaload_1.gif";

const Loading = () => {
  return (
    <div className="w-full h-screen bg-secondary/50 flex items-center justify-center">
      <img src={loader} alt="loader_gif" className="w-40 h-40 object-cover" />
    </div>
  );
};

export default Loading;
