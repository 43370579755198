import React from "react";
import { useQuery } from "@apollo/client";
import { ALL_ARTISTS } from "../../gql/gql";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { icons } from "../../constant";
import Loading from "../../components/Loading";

const Artist = () => {
  const { data, loading, error } = useQuery(ALL_ARTISTS);
  console.log({ data, loading, error });
  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <div className="max-w-[1200px] px-5 mx-auto ">
          <img
            src={icons.logo}
            alt=""
            className="lg:w-72 lg:h-28 w-40 h-14 mx-auto"
          />
          <div className="bg-background shadow-lg w-full flex p-3 mb-5">
            <Link to={"/"}>
              <h1 className="lg:text-[20px] text-[16px] text-secondary font-outfit font-normal">
                Home /
              </h1>
            </Link>
            <h1 className="lg:text-[20px] text-[16px]  font-outfit text-main font-normal">
              Artist
            </h1>
          </div>
          <div className=" mb-10  grid lg:grid-cols-4 grid-cols-2  gap-4 ">
            {data?.artist?.map((a) => (
              <Link to={`/artist/${a.id}`}>
                <div className=" bg-white shadow-xl p-3  " key={a.id}>
                  <img
                    src={a.artist_profile_image_url}
                    alt=""
                    className="lg:w-[300px] lg:h-[270px] w-[200px] h-[150px]  "
                  />
                  <div className="lg:py-5  mx-auto text-center">
                    <h1 className="lg:text-2xl text-sm font-outfit leading-8 font-semibold lg:mb-2 text-black tracking-wider  ">
                      {a.artist_name}
                    </h1>
                    {/* <span className="lg:text-[26px] text-sm font-outfit font-normal text-black tracking-wider">
                    {a.year_born}
                  </span> */}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default Artist;
