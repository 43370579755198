import React from "react";
import { images } from "../../constant";
import { Link } from "react-router-dom";
const Event = () => {
  return (
    <div className="relative w-full h-[700px]" id="event">
      <img
        src={images.eventbg}
        alt=""
        className="absolute w-full h-[700px] object-cover top-0 left-0 opacity-50"
      />
      <div className="absolute w-full h-[700px] bg-background/20 top-0 left-0" />
      <div className="max-w-[1200px] mx-auto ">
        <div className="absolute max-w-[1200px] w-full lg:px-7 p-7 lg:py-16 ">
          <h1
            className="lg:text-[20px] text-sm text-secondary font-outfit font-normal uppercase mb-2"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            Event
          </h1>
          <h2
            className="lg:text-[32px] text-lg text-black font-garamond font-bold tracking-wider uppercase"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            Event We Held On
          </h2>
          <div className="lg:my-10">
            <div className="lg:grid lg:grid-cols-2 ">
              <div
                className="  grid grid-cols-2 "
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <img
                  src={images.eventImg1}
                  alt=""
                  className="w-full h-full p-2 rounded-2xl "
                />
                <img
                  src={images.eventImg3}
                  alt=""
                  className="w-[90%] h-auto row-span-2 px-2 my-7  rounded-2xl "
                />
                <img
                  src={images.eventImg2}
                  alt=""
                  className="w-full h-full p-2  rounded-2xl"
                />
              </div>
              <div className="text-center lg:py-16 py-7 px-5">
                <h1
                  className="lg:text-[28px] text-lg text-black font-garamond font-bold  uppercase"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  Art Fair, Show & Festival
                </h1>
                <p
                  className="lg:text-2xl text-[16px] text-secondary font-outfit font-normal lg:my-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  MULA features a diverse range of artist booths where local and
                  international artists can display and sell their artworks.
                  This could include paintings, sculptures, photography,
                  ceramics, digital art and more. Artists can also showcase
                  their creative artworks to interact the audience
                </p>
                <Link to={"/event"}>
                  <button
                    className="bg-main border-none mt-2  text-white font-outfit font-normal   w-[65%] text-center"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Browse All Events
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
