import React from "react";
import { icons, images } from "../../constant";

const Ads = () => {
  return (
    <div className="max-w-[1200px]  p-5  mx-auto  ">
      <div className="grid lg:grid-cols-2">
        <img
          src={images.ads}
          alt=""
          className="lg:w-[500px] lg:h-[550px] object-cover"
          data-aos="fade-up"
          data-aos-duration="700"
        />
        <div className="lg:flex lg:py-48">
          <div className="">
            <h1
              className="text-main lg:text-[32px] tracking-wider text-lg font-garamond font-bold"
              data-aos="fade-up"
              data-aos-duration="700"
            >
              Mula Art Gallery App is Here!
            </h1>
            <p
              className="lg:text-2xl tracking-wide text-[16px] font-normal font-outfit text-main lg:mt-3"
              data-aos="fade-up"
              data-aos-duration="700"
            >
              Looking for more masterpiece?
            </p>
            <p
              className="lg:text-2xl tracking-wide text-[16px] font-normal font-outfit text-main"
              data-aos="fade-up"
              data-aos-duration="700"
            >
              Download here and enjoy further feature!
            </p>
          </div>
          <div
            className="flex justify-evenly  lg:block mt-5 lg:mt-0 lg:py-2  mx-auto"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <a href="https://play.google.com/store/apps/details?id=com.mula&hl=en&gl=US">
              <img
                src={icons.playstore}
                alt=""
                className="lg:w-30 w-10 h-10 object-cover  lg:mb-4 rounded-lg"
              />
            </a>
            <a href="https://apps.apple.com/mm/app/mula-art-gallery/id6448781719">
              {" "}
              <img
                src={icons.ios}
                alt=""
                className="w-30 h-10 object-cover rounded-lg"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads;
