// import React, { useEffect, useState } from "react";
// import { icons, images } from "../../constant";
// import { Link } from "react-router-dom";

// const Hero = () => {
//   const [show, setShow] = useState(false);

//   const showHandler = () => {
//     setShow(!show);
//   };

//   const [showScrollButton, setShowScrollButton] = useState(true);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       if (scrollPosition > 500) {
//         setShowScrollButton(false);
//       } else {
//         setShowScrollButton(true);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
//   return (
//     <div className="w-full lg:h-screen h-[550px] overflow-x-hidden">
//       <img
//         src={images.herobg}
//         alt=""
//         className="w-full lg:h-screen h-[550px] object-cover absolute opacity-20 "
//       />
//       <div className="w-full lg:h-screen h-[550px] absolute bg-black/30"></div>
//       <div className=""></div>
//       <div
//         className={
//           showScrollButton
//             ? "absolute grid grid-cols-12  z-20  w-full lg:h-[70px] h-[50px] mt-5  "
//             : "grid grid-cols-12 mt-5 z-20  w-full lg:h-[70px] h-[50px] bg-white shadow-lg fixed top-[-20px]  "
//         }
//       >
//         <div className="lg:col-span-10 col-span-9 text-center mx-auto">
//           <img
//             src={icons.logo}
//             alt=""
//             className="w-[150px] h-14] ml-16 lg:w-[300px] lg:h-[70px] object-cover "
//           />
//         </div>

//         <img
//           src={icons.menu}
//           alt=""
//           className={
//             show
//               ? "hidden"
//               : "w-6 h-4 ml-10 lg:w-10 lg:h-7 lg:col-span-2 col-span-3  my-5 "
//           }
//           onClick={showHandler}
//         />
//       </div>
//       {/* mobile nav */}

//       <div
//         className={
//           show
//             ? "lg:w-[300px] w-[150px] h-[100vh] fixed top-0  bg-white backdrop-blur-sm bg-opacity-30 px-4 py-7 lg:px-12 lg:py-10 right-0 ease-in  duration-500 z-30"
//             : "lg:w-[300px] w-[150px] h-[100vh] fixed top-0  bg-white backdrop-blur-sm bg-opacity-30 px-4 py-7 lg:px-12 lg:py-10 right-[-300px] ease-in   z-30"
//         }
//       >
//         <img
//           src={icons.cross}
//           alt=""
//           className=" w-5 h-5 mb-5  lg:w-10 lg:h-10 lg:mb-10"
//           onClick={showHandler}
//         />

//         <ul className=" lg:text-2xl text-[15px] font-outfit font-normal text-secondary   ">
//           <li className="mb-10 ml-2 lg:ml-5  menu-items-link inline-block">
//             <a href="#collections">Collection</a>
//           </li>

//           <Link to={"/artist"}>
//             <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block">
//               Artists
//             </li>
//           </Link>
//           <Link to={"/gallery"}>
//             <ul className="">
//               <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block ">
//                 Gallery
//               </li>
//             </ul>
//           </Link>

//           <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block">
//             <a href="#event">Event</a>
//           </li>

//           <Link to={"/brandstory"}>
//             <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block">
//               Brand Story
//             </li>
//           </Link>

//           <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block">
//             <a href="#footer">Contact Us</a>
//           </li>
//         </ul>
//       </div>

//       <div className="lg:w-[800px] lg:h-[350px] w-[320px] relative left-10 h-[260px]  border-l-2 border-white  lg:left-52  lg:top-56 top-24">
//         <div className="absolute left-3 lg:left-12  wow animate__animated animate__fadeInDown">
//           <h1 className="lg:text-[46px] text-[28px] text-black font-semibold font-garamond hello tracking-wide">
//             MULA: The Art Gallery, Timeless art for an ever-changing world.
//           </h1>
//           <p className="lg:text-xl text-sm text-secondary font-outfit font-normal mt-5 lg:mb-4 mb-3 lg:w-[500px] para">
//             MULA is a contemporary art gallery located in Yangon City. Our
//             mission is to provide a platform for emerging artists to showcase
//             their work and establish a foothold in the art world.
//           </p>
//           <Link to={"/gallery"}>
//             <button className="lg:text-xl text-black font-karla font-normal py-[5px] px-[10px] border rounded-md text-[12px] gallery ">
//               Visit Gallery
//             </button>
//           </Link>
//         </div>
//       </div>
//       <div className="absolute right-0 w-[200px] border-b-2 border-white top-[45%] lg:w-[370px] lg:top-[70%]">
//         <h1 className="lg:text-xl text-[12px] text-white py-1 font-outfit font-normal">
//           Popular Artworks
//         </h1>
//       </div>
//       <div className="absolute flex top-[50%] right-0  lg:top-[77%] ">
//         <Link to={"/gallery"}>
//           <img
//             src={images.elephant}
//             alt=""
//             className="w-[65px] h-[80px] rounded-md lg:w-[120px] lg:h-[150px]"
//           />
//         </Link>
//         <Link to={"/gallery"}>
//           <img
//             src={images.truck}
//             alt=""
//             className="w-[65px] h-[80px] ml-5 rounded-md lg:w-[120px] lg:h-[150px]"
//           />
//         </Link>
//         <Link to={"/gallery"}>
//           <img
//             src={images.tiger}
//             alt=""
//             className="w-[65px] h-[80px] ml-5 rounded-md lg:w-[120px] lg:h-[150px] "
//           />
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default Hero;
import React, { useState, useEffect } from "react";
import { images, icons } from "../../constant";
import { Link } from "react-router-dom";
const Hero = () => {
  const [show, setShow] = useState(false);
  const showHandler = () => {
    setShow(!show);
  };
  const [showScrollButton, setShowScrollButton] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 500) {
        setShowScrollButton(false);
      } else {
        setShowScrollButton(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className="w-full lg:h-screen h-[550px] overflow-hidden relative"
      id="hero"
    >
      <img
        src={images.herobg}
        alt=""
        className="w-full lg:h-screen h-[550px] absolute opacity-50 object-cover"
      />
      <div className="w-full lg:h-screen h-[550px] absolute bg-black/30"></div>
      {/* <div
        className={
          showScrollButton
            ? ""
            : "w-10 h-10  fixed bottom-5 right-5 bg-main z-10 rounded-full transition-all duration-700"
        }
      >
        <a href="#hero">
          <img src={icons.scrollbtn} alt="" />
        </a>
      </div> */}
      <div
        className={
          showScrollButton
            ? "lg:mt-5 mt-3 relative"
            : " bg-white shadow-lg left-0 fixed w-full z-10"
        }
      >
        <img
          src={icons.logo}
          alt=""
          className="lg:w-80 w-[150px]  lg:h-16 h-10 object-cover mx-auto  "
        />
        <img
          src={icons.menu}
          alt=""
          className={
            show
              ? "hidden"
              : "lg:w-10 w-6 h-5 lg:h-10 absolute lg:top-2 top-2  right-[12%]"
          }
          onClick={showHandler}
        />
      </div>
      <a href="#hero">
        <img
          src={icons.scrollbtn}
          alt=""
          className={
            showScrollButton
              ? "hidden"
              : "fixed bg-main lg:w-10 rounded-full z-10 lg:h-10 w-8 h-8 lg:right-8 right-4 bottom-4 lg:bottom-8"
          }
        />
        {/* <a href="#hero"><img src={icons.scrollbtn} alt="" /></a> */}
      </a>
      <div className="max-w-[1200px] mx-auto">
        <div className="relative  h-auto max-w-[1200px] w-full">
          <div className="absolute lg:w-[800px] left-3   wow animate__animated animate__fadeInDown  h-auto w-[320px]    border-l-2 border-white lg:mt-14  mt-5 lg:px-8 px-4">
            <h1 className="lg:text-[46px] text-[28px] text-black font-semibold font-garamond hello tracking-wide">
              MULA: The Art Gallery, Timeless art for an ever-changing world.
            </h1>
            <p className="lg:text-xl text-sm text-secondary font-outfit font-normal mt-5 lg:mb-4 mb-3 lg:w-[500px] para">
              MULA is a contemporary art gallery located in Yangon City. Our
              mission is to provide a platform for emerging artists to showcase
              their work and establish a foothold in the art world.
            </p>
            <Link to={"/gallery"}>
              <button className="lg:text-xl text-black font-karla font-normal py-[5px] px-[10px] border rounded-md text-[12px] gallery ">
                Visit Gallery
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div
        className={
          show
            ? "lg:w-[300px] w-[150px] h-screen fixed top-0  bg-white backdrop-blur-sm bg-opacity-30 p-5 lg:px-12 lg:py-10 right-0 ease-in  duration-500 z-30"
            : "lg:w-[300px] w-[150px] h-screen fixed top-0  bg-white backdrop-blur-sm bg-opacity-30 p-5 lg:px-12 lg:py-10 right-[-300px] ease-in duration-500   z-30"
        }
      >
        <img
          src={icons.cross}
          alt=""
          className=" w-6 h-4 mb-5  lg:w-10 lg:h-7 lg:mb-10 object-cover"
          onClick={showHandler}
        />

        <ul className=" lg:text-2xl text-[15px] font-outfit font-normal text-secondary   ">
          <li className="mb-10 ml-2 lg:ml-5  menu-items-link inline-block">
            <a href="#collection">Collection</a>
          </li>

          <Link to={"/artist"}>
            <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block">
              Artists
            </li>
          </Link>
          <Link to={"/gallery"}>
            <ul className="">
              <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block ">
                Gallery
              </li>
            </ul>
          </Link>

          <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block">
            <a href="#event">Event</a>
          </li>

          <Link to={"/brandstory"}>
            <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block">
              Brand Story
            </li>
          </Link>

          <li className="mb-10 ml-2 lg:ml-5 menu-items-link inline-block">
            <a href="#footer">Contact Us</a>
          </li>
        </ul>
      </div>
      <div className="absolute right-[-20px] w-[250px]   lg:w-[450px] bottom-5 h-auto p-5">
        <h1 className="lg:text-xl text-[12px] border-b-2 border-white text-white py-1 font-outfit font-normal mb-5 ml-10">
          Popular Artworks
        </h1>
        <div className=" grid grid-cols-3 gap-2  ">
          <Link to={"/gallery"}>
            <img
              src={images.popularArtwork1}
              alt=""
              className="w-[70px] h-[80px] rounded-sm lg:rounded-xl lg:w-[120px] lg:h-[150px] object-cover"
            />
          </Link>
          <Link to={"/gallery"}>
            <img
              src={images.popularArtwork2}
              alt=""
              className="w-[70px] h-[80px]  rounded-sm lg:rounded-xl lg:w-[120px] lg:h-[150px] object-cover"
            />
          </Link>
          <Link to={"/gallery"}>
            <img
              src={images.popularArtwork3}
              alt=""
              className="w-[70px] h-[80px]  rounded-sm lg:rounded-xl lg:w-[120px] lg:h-[150px]  object-cover"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
